import IT from './translations_it.json'
import EN from './translations_en.json'
import DE from './translations_de.json'

export default {
	legacy: false,
	locale: 'en',
	warnHtmlMessage: false,
	messages: {
		it: IT,
		en: EN,
		de: DE,
	},
}
